<template>
  <SpFinder
    :id="id"
    ref="finder"
    :url="{
      setting: dbTableFieldSetting,
      data: dbTableFieldList
    }"
    title="数据表字段"
  />
</template>

<script>
import { dbTableFieldList, dbTableFieldSetting } from '@/api/finder';

export default {
  data () {
    const id = this.$route.query.id;
    return {
      id,
      dbTableFieldList,
      dbTableFieldSetting
    };
  },
  mounted () {},
  methods: {}
};
</script>
